import React from "react"
import Layout from '../layouts'
import Section from '../components/section'
import RoadMap from '../components/roadMap'
import HowToWork from '../components/howToWork'
import EarthaToken from '../components/earthaToken'
import Where2Buy from '../components/where2Buy'
import Communities from '../components/communities'
import Partner from '../components/partner'
import FindUs from '../components/findus'
import IcoInform from '../components/IcoInform'
import Accordion from '../components/Accordion'
import { graphql, useStaticQuery } from "gatsby"
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import "./../styles/global.scss";



const IndexPage = () => {
  //   const data = useStaticQuery(graphql`
  //   query {
  //     allSectionsJson {
  //       edges {
  //         node {
  //           titleList {
  //               text
  //               className
  //           }
  //           subTitleList {
  //               text
  //               html
  //               className
  //               liList {
  //                   text
  //                   className
  //                   isLi
  //               }
  //           }
  //           isTop
  //           className
  //           subContents {
  //               text
  //               className
  //               ContentType
  //               Type
  //               Link
  //               toolTip
  //               filled
  //           }
  //           charts {
  //               type
  //               className
  //               data {
  //                   labels
  //                   datasets {
  //                       data
  //                       backgroundColor
  //                       color
  //                       hoverOffset
  //                       borderColor
  //                   }
  //               }
  //               options {
  //                   responsive
  //                   plugins {
  //                       legend {
  //                           position
  //                           align
  //                       }
  //                       title {
  //                           display
  //                           text
  //                           font {
  //                               size
  //                               weight
  //                           }
  //                           color
  //                           backgroundColor
  //                       }
  //                   }
  //               }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)
  if (typeof window !== "undefined" && !localStorage.getItem("theme")) {
    window.__setPreferredTheme("dark")
  }
    const intl = useIntl()
    const sections = intl.messages.sections


    return (
        <Layout>
            <IcoInform />
            {sections.map((section, i) => (
                <Section 
                    titleList={section.titleList} 
                    subTitleList={section.subTitleList}
                    isTop={section.isTop === "true"}  
                    linkTextList={section.linkTextList}
                    subContents={section.subContents} 
                    className={section.className}
                    key={i}
                    charts={section.charts}
                    chartHistory={section.chartHistory}
                > </Section>
            ))}
            <EarthaToken />
            <Where2Buy />
            <HowToWork />
            <RoadMap />
            <FindUs />
            <Partner />
            <Accordion  />
            <Communities />
        </Layout>
    )
}
export default IndexPage
