import React from "react"
import { Link } from 'gatsby'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import LinkButton from "./styledComponents/LinkButton"
import Image from "./Image"
import { useMediaQuery } from '@react-hook/media-query'

const HideSmall = styled.div`
  display: contents;
  min-width: 210px;
  @media (max-width: 960px) {
    display: none;
  }
`

const ShowSmall = styled.div`
  display: contents;
  min-width: 210px;
  @media (min-width: 960px) {
    display: none;
  }
`

const BuyButtonContainer = styled.div`
flex: 1;
`
const BridgeContainer = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    place-content: center;
`
const HowToWorkArea = styled.div`
    max-width: 1020px;
    margin: 0 auto;
`

const ImageContainer = styled.div`
    width: 50px;
`
const LogoText = styled.span`
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    flex: 1;
    @media screen and (max-width: 768px) {
        font-size: 28px;
    }
`
const LogoWrapper = styled.div`
    display: flex;
    width: 300px;
    align-items: center;
    @media screen and (max-width: 768px) {
        padding-bottom: 12px;
    }
`

const PriceWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
`
const PriceCurrency = styled.span`
    font-weight: 700;
    line-height: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Helvetica;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
`

const ExchangeItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 2px;

    @media screen and (min-width: 768px) {
        padding-bottom: 2px;
        flex-direction: row;
    }

    @media screen and (min-width: 1920px) {
        padding-top: 48px;
        padding-bottom: 2px;
    }
`

const ExchangesItems = styled.ul`
    margin: 0 auto 51px;
    padding: 35px 40px 0;
    border-radius: 16px;
    max-width: 1360px;
    box-sizing: border-box;
    position: relative;

`

const Where2Buy = () => {
    const intl = useIntl()
    const whereToBuy = intl.messages.whereToBuy
    const matches = useMediaQuery('(max-width: 960px)')

    return (
        <HowToWorkArea>
        <h1 style={{"marginTop": "4rem"}} className="b_big_font normal_weight" > {whereToBuy.title} </h1>
            <div>
                <ExchangesItems>
                    {whereToBuy.list.map((x, i) => (
                        <>
                            <ExchangeItem className={x.className}>
                                <LogoWrapper >
                                    <ImageContainer >
                                        <Image filename={x.logo}  className={x.imageClassName} />
                                    </ImageContainer >
                                    <div style={{"width": 10, "height": 2}} />
                                    <LogoText>
                                        {x.logoText}
                                    </LogoText>
                                </LogoWrapper >
                                <PriceWrapper>
                                    <PriceCurrency>{x.price} </PriceCurrency>
                                </PriceWrapper>
                                <LogoWrapper >
                                    <ImageContainer style={{"margin": "0 auto"}}>
                                        <a 
                                          href={x.chartLink}
                                          target="_blank"
                                        >
                                        <Image filename="monitoring_blue.png"  className={x.imageClassName} />
                                    </a>
                                    </ImageContainer >
                                </LogoWrapper >
                                    <ShowSmall>
                                    {
                                        <LinkButton target="_self" href={x.buyLink} className={x.className} filled=""  text="Buy" > </LinkButton>
                                    }
                                    </ShowSmall>
                                    <HideSmall> 
                                    {
                                        <LinkButton target="_blank" href={x.buyLink} className={x.className} filled=""  text="Buy" > </LinkButton>
                                    }
                                    </HideSmall> 
                            </ExchangeItem>
                            <BridgeContainer>
                                <ShowSmall>
                                {
                                    <LinkButton target="_self" href={x.bridgeLink} className={x.className} filled=""  text="Bridge" > </LinkButton>
                                }
                                </ShowSmall>
                                <HideSmall> 
                                {
                                    <LinkButton target="_blank" href={x.bridgeLink} className={x.className} filled=""  text="Bridge" > </LinkButton>
                                }
                                </HideSmall> 
                            </BridgeContainer>
                        </>
                    ))}
                </ExchangesItems>
            </div>
        </HowToWorkArea>
    )
}

export default Where2Buy
