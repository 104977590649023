import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';



const Button = styled.a`
    text-decoration: none;
    background: ${({filled}) => {
        return filled ? "var(--filled-button-background-color)" : "var(--button-background-color)"
    }};
    color: ${({filled}) => filled ? "var(--filled-button-color)" : "var(--button-color)"};
    border-radius: 100px;
    padding: 12px 16px;
    position: relative;
    border-color: ${({filled}) => filled ? "var(--button-border-color)" : "var(--button-border-color)"};
    border-width: 1px;
    border-style: solid;

    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    min-width: 210px;
    padding: 14px 10px;
    font-size: 15px;
    margin: 12px 6px 6px 0px;
    font-family: var(--font-family);
    font-weight: 600;
    :hover {
        background: ${({filled}) => filled ? "var(--filled-button-hover-background-color)" : "var(--button-hover-background-color)"};
        color: ${({filled}) => filled ? "var(--button-hover-color)" : "var(--button-hover-color)"};
        border-color: transparent;
    }
    pointer-events: ${({disabled}) => disabled ? "none" : "auto"};
    -webkit-user-select: ${({disabled}) => disabled ? "none" : "auto"};
    -moz-user-select: ${({disabled}) => disabled ? "none" : "auto"};
    -ms-user-select: ${({disabled}) => disabled ? "none" : "auto"};
    user-select: ${({disabled}) => disabled ? "none" : "auto"};
    opacity: ${({disabled}) => disabled ? 0.4 : 1};
`
const RedButton = styled(Button)`
    background: ${({filled}) => {
        return filled ? "var(--red)" : "var(--red)"
    }};
    border-color: ${({filled}) => filled ? "var(--red)" : "var(--red)"};
`

// export default function LinkButton(props) {
export default class LinkButton extends React.Component {
    render() {
    const uuid = uuidv4();
    const props = this.props
    let button;
    if(props.themeColor === "red") {
        button = <RedButton
              data-tip={props.toolTip ? props.toolTip : ""}
              href={props.href} 
              style={props.style}
              className={props.className} 
              filled={props.filled}
              target={props.target ? props.target : "_blank"}
              disabled={props.disabled}
              download={props.download}
              >
              {props.text}
          </RedButton>
    } else {
        button = <Button
              data-tip={props.toolTip ? props.toolTip : ""}
              href={props.href} 
              style={props.style}
              className={props.className} 
              filled={props.filled}
              target={props.target ? props.target : "_blank"}
              download={props.download}
              disabled={props.disabled}
              >
              {props.text}
          </Button>

    }
        return (
            <>
                {button}
            </>
        )
    }
}
