import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import Image from "./Image"

const RoadMapArea = styled.div`
  position: relative;
  padding: 1rem;
  margin: 0 auto;
  max-width: 920px;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    border: 1px solid var(--time-line-end);
    right: 40px;
    top: 0;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (min-width: 960px) {
    padding: 2rem;
    &:before {
      left: calc(50% - 1px);
      right: auto;
    }
}
`

const RoadMapItem = styled.div`
  padding: 1rem;
  border: 2px solid var(--time-line-end);
  border-image: linear-gradient(to right, var(--time-line-start) 0%, var(--time-line-end) 100%);
  border-image-slice: 1;
  position: relative;
  margin: 1rem 3rem 1rem 1rem;
  clear: both;

  &:after,
  &:before {
    content: '';
    position: absolute;
  }

  &:before {
    right: -10px;
    top: calc(50% - 5px);
    border-style: solid;
    border-color: var(--time-line-end) var(--time-line-end) transparent transparent;
    border-width: 10px;
    transform: rotate(45deg);
  }

  @media screen and (min-width: 960px) {
    width: 40%;
    margin: 1rem;

    &:nth-of-type(2n) {
      float: right;
      margin: 1rem;
      border-image: linear-gradient(to right, var(--time-line-end) 0%, var(--time-line-start) 100%);
      border-image-slice: 1;

      &:before {
        right: auto;
        left: -10px;
      border-color:transparent transparent var(--time-line-end) var(--time-line-end);
      }
    }
  }
}
`

const RoadMapYear = styled.h2 `
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
  font-size: 1.8rem;
  background-color: $yearBgColor;
  line-height: 1;
  border-image: none;
  padding: .5rem 1rem 1rem;

  &:before {
    display: none;
  }

  @media screen and (min-width: 960px) {
    text-align: center;
      margin: 0 auto;

    &:nth-of-type(2n) {
      float: none;
      margin: 0 auto;
      border-image: none;

      &:before {
        display: none;
      }
    }
  }
}
`

const RoadMapTitle = styled.h3`
  margin: 0;
  font-size: 1.5em;
  padding: 0px;
  text-align: left;
`

const RoadMapMiniTitle = styled.h4`
  margin: 0;
  fontFamily: var(--font-family)
`

const RoadMap = () => {
    const data = useStaticQuery(graphql`
    query {
      allRoadMapsJson {
        edges {
          node {
            text
            miniTextList {
                text
                className
            }
            year
          }
        }
      }
      }
    `)
    const intl = useIntl()
    const roadMaps = intl.messages.roadMaps
    const roadMapsTop = intl.messages.roadMapsTop

    return (
        <div>
            <h1 style={{"marginTop": "4rem"}} className="b_big_font normal_weight"> {roadMapsTop.title} </h1>
            {roadMapsTop.textList.map((text, i) => (
                <h3 style={{textAlign: "left"}} key={i}>{text}</h3>
            ))}
            <RoadMapArea>
                {roadMaps.map((edge, i) => (
                    <RoadMapItem key={i}>
                        <RoadMapYear className="b_font" style={{"whiteSpace": "pre"}}>{edge.year} </RoadMapYear>
                        <RoadMapTitle className="b_font">{edge.text} </RoadMapTitle>
                        {edge.miniTextList.map((mini, j) => (
                            <div style={{"display":"flex", "alignItems":"center"}}>
                                {
                                mini.checked
                                    ?<Image filename="check.png" className="" style={{width: 16}} />
                                    :<span style={{width: 16}}></span>
                                }
                                <RoadMapMiniTitle className={mini.className} key={(i + 100) * j + 1} >{mini.text} <br /></RoadMapMiniTitle>
                            </div >
                        ))}
                    </RoadMapItem>
                ))}
            </RoadMapArea>
        </div>
    )
}

export default RoadMap
