import React, { useState, useCallback,useRef, useEffect, useLayoutEffect } from 'react'
import { useMediaQuery } from '@react-hook/media-query'

import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import styled from 'styled-components'

import "./../styles/menu.scss"

export function useToggle(initialState = false) {
  const [state, setState] = useState(initialState)
  const toggle = useCallback(() => setState(state => !state), [])

  return [state, toggle]
}


const StyledMenuItem = styled.span`
  text-decoration: none;
  margin: 0px;
  border-radius: 0.5rem;
  font-family: 'GT Haptik Regular';
  width: fit-content;
  :hover {
    border-radius: 8px;
  }
  @media (max-width: 960px) {
  }
`

const StyledExternalLink = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: block;
  margin: 0.25rem 0;
  width: 100%;
  cursor: pointer;
  :focus {
    outline: 0;
    opacity: 0.9;
  }
  :hover {
    * {
    }
  }
`

const StyledTitle = styled.p`
  display: block;
  font-weight: ${({ active }) => active && 500};
  border-radius: 8px;
  text-decoration: none;
  margin: 0;
  padding: 0;
  padding: 0.125rem 0.5rem 0px 0.5rem;
  width: fit-content;
  @media (max-width: 960px) {
    padding: 0;
  }
  color: var(--color);
  transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  :hover {
    transform: translate3d(2px, 2px, 10px);
  }
`

const FloatMenuNav = styled.nav`
  border-radius: 0.5rem;
  // display: flex;
  flex-direction: column;
  position: absolute;
  top: 1.65rem;
  left: -1rem;
  min-width: 200px;
  width: 100%;
  border-radius: 8px;
  height: 0;
  overflow: hidden;
  transition: max-height 0.4s 3s, opacity 0.6s;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04); z-index: 999;
  backdrop-filter: blur(120px);
  background-color: var(--bg-header);
  p {
    padding: 0px;
  }
  @media (max-width: 960px) {
    font-size: 1.125rem;
    position: initial;
    box-shadow: none;
    top: unset;
    left: unset;
    padding: 0;
    margin-top: 1rem;
    backdrop-filter: 'none';
    background-color: rgba(255, 255, 255, 0);
    width: auto;
    // width: 200px !important;
    height: auto;
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
`

export default function Menu(props) {
  const matches = useMediaQuery('only screen and (max-width: 960px)')
  const button = useRef()
  const [isOpen, updateIsOpen] = useState(false)

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      document.body.style.maxHeight = '-webkit-fill-available'
    }
    return () => (document.body.style.overflow = originalStyle)
  }, [isOpen]) // Empty array ensures effect is only run on mount and unmount

  // useEffect(() => {
  //   const handleClickOutside = e => {
  //     if (button.current.contains(e.target)) {
  //       return
  //     }
  //     updateIsOpen(false)
  //   }
  //
  //   document.addEventListener('mousedown', handleClickOutside)
  //
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [isOpen, updateIsOpen, matches])

    return (
        <button className="menuButton" tabIndex={0} ref={button} onClick={() => updateIsOpen(!isOpen)}>
        <span className="menuTitle">
        {props.data.languageSwitter ?
            <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
                <React.Fragment>
                    <span style={{ marginRight: '0.25rem' }} className="buttonCls">
                        {props.data.items.find(item => item.language == currentLocale).text}
                    </span>
                    <FloatMenuNav className="floatMenu" open={isOpen}>
                    {languages.map((language, index) => (
                        <StyledMenuItem tabindex={index} key={index}>
                            <StyledTitle onClick={() => changeLocale(language)}>
                                {props.data.items.find(item => item.language == language).text}
                            </StyledTitle>
                        </StyledMenuItem>
                    ))}
                    </FloatMenuNav>
                </React.Fragment>
            }
            </IntlContextConsumer>
            :
            <React.Fragment>
                <span style={{ marginRight: '0.25rem' }} className="buttonCls">{props.data.text} </span>
                <nav className="floatMenu">
                {props.data.items && props.data.items.map((item, index) => {
                    return (
                        <StyledMenuItem tabindex={index} key={index}>
                        {item.Link.split('.').slice(-1)[0] === 'pdf' ? (
                            <StyledExternalLink href={item.Link} target="_blank" rel="noopener noreferrer">
                            <StyledTitle>{item.text}</StyledTitle>
                            </StyledExternalLink>
                        ) : (
                            <StyledExternalLink href={item.Link}>
                            <StyledTitle>{item.text}</StyledTitle>
                            </StyledExternalLink>
                        )}
                        </StyledMenuItem>
                    )
                })}
            </nav>
            </React.Fragment>
        }
        </span>
    </button>
    )
}
