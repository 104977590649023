import * as React from 'react';
import Pdf from "./Pdf"
import Chart from "./Chart"
import { Link } from "gatsby" 
import { useMediaQuery } from '@react-hook/media-query'
import styled from 'styled-components'
import { useIntl, injectIntl } from "gatsby-plugin-intl"



import "./../styles/section.scss";
import LinkButton from "./styledComponents/LinkButton"
const HideSmall = styled.div`
  display: contents;
  min-width: 210px;
  @media (max-width: 960px) {
    display: none;
  }
`

const ShowSmall = styled.div`
  display: contents;
  min-width: 210px;
  @media (min-width: 960px) {
    display: none;
  }
`

const MiniBr = styled.br`
    height: 14px;
    display: block; 
    content: "";
`

const SubSubContentsSpan = styled.span`
display: block;
font-size: 12px;
line-height: 12px;
height: 12px;
`
const SubContentsSpan = styled.span`
    display: flex;
    white-space: pre-wrap;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`

const ChartSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`

const Ul = styled.ul`
    max-width: 960px;
    margin: .5rem auto 1.45rem;
`
const SectionContainr = styled.div`
    max-width: 960px;
    margin: 0 auto;
`

const Section = (props) => {
  const matches = useMediaQuery('(max-width: 960px)');
    const className = "container_text_section" + " " + (props.className ? props.className : "");

    return (
        props.isTop
        ?
        <div className={className}>
                {props.titleList.length ? props.titleList.map((t, i) => (
                        <h1 key={i} className="topH1">{t.text}</h1>
                ) ) : null}
                {props.subTitleList.length ? props.subTitleList.map((t, i) => (
                        <h2 className={"topH2 " + t.className} key={i}>{t.text}</h2>
                ) ) : null}
                <nav className="topButtonListNav">
                    {props.subContents && props.subContents.filter(x => x.ContentType === "button").map((button, i) => (
                         button.Type === "Download" 
                            ?
                                <Pdf className={button.className} filename={button.Link} text={button.text} filled={button.filled} key={i} toolTip={button.toolTip} isStatic={true} />
                            :
                    <React.Fragment>
                        <ShowSmall>
                        {
                            <LinkButton target="_self" href={button.Link} className={button.className} filled={button.filled} key={i} text={button.text} toolTip={button.toolTip}> </LinkButton>
                        }
                        </ShowSmall>
                        <HideSmall> 
                        {
                            <LinkButton target="_blank"  href={button.Link} className={button.className} filled={button.filled} key={i} text={button.text} toolTip={button.toolTip}> </LinkButton>
                        }
                        </HideSmall> 
                    </React.Fragment>
                    ))}
                </nav>
            </div>
        :
        <div className={className}>
            <SectionContainr>
            {props.titleList.length ? props.titleList.map((t, i) => (
                    <h1 key={i} className={t.className} >{t.text}</h1>
            ) ) : null}
            {props.subTitleList.length ? props.subTitleList.map((subTitle, i) => (
                <React.Fragment key={i}>
                        {subTitle.html 
                            ?
                            (
                                <h3  className={subTitle.className} dangerouslySetInnerHTML={{__html: subTitle.html}}></h3>
                            )
                            :
                            (
                                <h3 className={subTitle.className}>{subTitle.text}</h3>
                            )
                        }
                    {subTitle.liList && subTitle.liList.length &&
                            <Ul>
                            {subTitle.liList.map((li, j) => (
                                li.isLi 
                                    ?
                                    <li key={(i+100)+j} className={li.className}>{li.text}</li>
                                    :
                                    <div key={(i+100)+j} className={li.className}>{li.text}</div>
                            ) ) }
                            </Ul>
                    }
                    </React.Fragment>
                ) ) : null}
                {props.subContents && props.subContents.filter(x => x.ContentType === "button").map((button, i) => (
                    button.Type === "Download" 
                        ?
                            <Pdf className={button.className} filename={button.Link} text={button.text} filled={button.filled} key={i} toolTip={button.toolTip} isStatic={true} />
                            :
                    <React.Fragment>
                    <ShowSmall>
                    {
                        <LinkButton  href={button.Link} target="_self" className={button.className} filled={button.filled} key={i} text={button.text} toolTip={button.toolTip}> </LinkButton>
                    }
                    </ShowSmall>
                    <HideSmall> 
                    {
                        <LinkButton  href={button.Link} target="_blank" className={button.className} filled={button.filled} key={i} text={button.text} toolTip={button.toolTip}> </LinkButton>
                    }
                    </HideSmall> 
                    </React.Fragment>
                ))}
                {
                    props.subContents && props.subContents.length
                        ? props.subContents.filter(x => x.ContentType === "link").map((t, i) => (
                            <React.Fragment key={i}>

                                    <SubContentsSpan>
                                        {t.text}<a key={i} href={t.Link} target="_blank" className={t.className}>{t.linkText}</a>
                                    </SubContentsSpan>
                                    {
                                        t.subText &&
                                        <SubSubContentsSpan>{t.subText}</SubSubContentsSpan>
                                    }
                                    {t.addBr ? <MiniBr  /> : null}
                            </React.Fragment>
                        ) ) 
                        : null}
        
            {props.charts && props.charts.length ? (
                <React.Fragment>
                <ChartSection >
                    { props.charts.map((chart, i) => (
                        <Chart key={i} type={chart.type} data={chart.data}  options={chart.options} className={chart.className}/>
                    ) ) }
                </ChartSection>
                </React.Fragment>
            ): null}
            {props.charts && props.charts.length ? (
                <React.Fragment>
                    { props.chartHistory.map((t, i) => (
                        <SubContentsSpan>
                            {t.text}<a key={i} href={t.Link} target={matches.width ? "_self" : "_blank"} className={t.className}>{t.linkText}</a>
                        </SubContentsSpan>
                    ))}
                </React.Fragment>
            ): null}
            </SectionContainr>
        </div>
    )
}
export default Section
