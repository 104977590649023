import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { useIntl, injectIntl } from "gatsby-plugin-intl"

const HideSmall = styled.span`
  @media (max-width: 960px) {
    display: none;
  }
`
const ShowSmall = styled.span`
  @media (min-width: 960px) {
    display: none;
  }
`

const HowToWorkArea = styled.div`
        p {
            font-size: 1em;
            line-height: 1.75em;
            border-image: linear-gradient(to right, var(--time-line-start) 0%, var(--time-line-end) 100%);
            border-image-slice: 1;
            border-width: 3px;
            margin: 0;
            padding: 20px;
            counter-increment: section;
            position: relative;
            &:before {
                content: counter(section);
                color: #fff;
                // color: var(--button-blue);
                position: absolute;
                border-radius: 50%;
                padding: 10px;
                height: 1.25em;
                width: 1.25em;
                // background-color: #34435E;
                background-color: var(--button-blue);
                text-align: center;
                line-height: 1.25em;
                font-size: 1em;
            }
        }
        p:first-child {
            border-top: 0;
            border-top-right-radius:0;
            border-top-left-radius:0;
        }
        p:last-child {
            border-bottom-right-radius:0;
            border-bottom-left-radius:0;
        }
        @media (max-width: 960px) {
          p{
            margin: 0 auto;
            width: 68%;
          }
          p:nth-child(n) {
              border-left: 3px solid;
              padding-right: 0;
              white-space:break-spaces;
              &:before {
                  right: 100%; 
                  margin-right: -18px;
              }
          }
        }
        @media(min-width: 960px) {
        margin: .5rem auto 1.45rem;
        max-width: 920px;
            p {
                border-top: 3px solid;
            }
            p:nth-child(odd) {
                border-right: 3px solid;
                padding-left: 0;

                &:before {
                    left: 100%; 
                    margin-left: -19px;
                    top: calc(50% - 20px);
                }
            }
            p:nth-child(even) {
                border-left: 3px solid;
                padding-right: 0;

                &:before {
                    right: 100%; 
                    top: calc(50% - 20px);
                    margin-right: -19px;
                }
            }
        }
`

const HowToWork = () => {
    const data = useStaticQuery(graphql`
    query {
      allHowToWorksJson {
        edges {
          node {
            textList {
                text
                className
            }
          }
        }
      }
      }
    `)
    const intl = useIntl()
    const howToWorks = intl.messages.howToWorks

    return (
        <HowToWorkArea>
        <h1 style={{"marginTop": "4rem"}} className="b_big_font normal_weight" > How To Work </h1>
            <HideSmall>
                <div>
                    {howToWorks.map((node, i) => (
                        <p key={i}>
                            {node.textList.map((x, j) => (
                                    <h3 key={(i + 100) * j + 1} className={x.className} style={{"marginBottom": 0, "fontWeight": "400", "fontFamily": "var(--font-family)", "textAlign": "left"}} >{x.text} <br/></h3>
                            ))}
                            <br />
                        </p>
                    ))}
                </div>
            </HideSmall>
            <ShowSmall>
                <div>
                    {howToWorks.map((node, i) => (
                        <p key={i}>
                            {node.textList.map((x, j) => (
                                <span key={(i + 100) * j + 1} style={{"marginBottom": 0, "fontWeight": "400", "fontFamily": "var(--font-family)"}} className={x.className}>{x.text} <br/> </span>
                            ))}
                        <br />
                        </p>
                    ))}
                </div>
            </ShowSmall>
        </HowToWorkArea>
    )
}

export default HowToWork
