import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Menu from './menu'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { IntlContextConsumer, changeLocale, useIntl, injectIntl } from "gatsby-plugin-intl"
// import { ReactComponent as CloseIcon } from "./../images/close.svg";
//
// const styledCloseIcon = styled(CloseIcon) `
// stroke: #fff;
// `;



import { Sun, Moon } from 'react-feather'
import Image from "./Image"

const StyledHeaderContainer = styled.header`
    width: 100%;
    background: var(--bg);
  z-index: 3;
  position: sticky;
  top: -1px;
`

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 1rem 0rem;
  width: 960px;
  // background: #22272e61;
  background: transparent;
  background: var(--bg);
  transition: background-color 0.25s ease;
  @media (max-width: 960px) {
    width: 100%;
    padding: 1.5rem 2rem;
    height: ${({ open }) => (open ? '100vh' : '100%')};
  }
`

const StyledNav = styled.nav`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  transition: right 0.25s ease;
  justify-content: flex-end;
  @media (max-width: 960px) {
    background: var(--bg);
    position: fixed;
    top: 0px;
    right: ${({ open }) => (open ? '0px' : '-100%')};
    align-items: flex-start;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    z-index: 4999;
    padding: 2rem;
    overflow: auto;
  }
  > * + * {
    margin-left: 24px;
  }
  @media (max-width: 960px) {
    > * + * {
      margin-left: 0;
    }
  }
`

const StyledNavTitleWrapper = styled.nav`
  display: flex;
  align-items: center;
`

const StyledHomeLink = styled(Link)`
  display: none;
  // max-height: 48px;
  align-items: start;
  @media (min-width: 960px) {
    display: flex;
  }
`

const MenuToggle = styled.button`
  border: none;
  background-color: transparent;
  display: none;
  z-index: 9999;
  width: 24px;
  height: 24px;
  padding: 0px;
  :focus {
    outline: none;
  }
  @media (max-width: 960px) {
    display: initial;
    position: ${({ open }) => (open ? 'fixed' : 'relative')};
    right: ${({ open }) => (open ? '1.5rem' : 'initial')};
    top: ${({ open }) => (open ? '1.5rem' : 'initial')};
  }
`



const HideSmall = styled.span`
  @media (max-width: 960px) {
    display: none;
  }
`

const ShowSmall = styled.span`
  display: block;
  @media (min-width: 960px) {
    display: none;
  }
`

const Header = props => {
  const matches = useMediaQuery('only screen and (max-width: 960px)')
  const node = useRef()
  const button = useRef()
  const [isMenuOpen, updateIsMenuOpen] = useState(false)


    const data = useStaticQuery(graphql`
    query {
      allLanguageListJson {
        edges {
          node {
            text
            id
            languageSwitter
            items {
                text
                language
                Link
            }
          }
        }
      }
    }
  `)
    const intl = useIntl()
    const headers = intl.messages.headers
    const languageList = data.allLanguageListJson.edges.map(edge => edge.node)

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'
      document.body.style.maxHeight = '-webkit-fill-available'
    }
    return () => (document.body.style.overflow = originalStyle)
  }, [isMenuOpen]) // Empty array ensures effect is only run on mount and unmount

  useEffect(() => {
    const handleClickOutside = e => {
      if (node.current.contains(e.target) || button.current.contains(e.target)) {
        return
      }
      updateIsMenuOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuOpen, updateIsMenuOpen, matches])

  return (
      <StyledHeaderContainer >
    <StyledHeader open={isMenuOpen} >
      <StyledNavTitleWrapper>
        <StyledHomeLink
          to="/"
          style={{
            textDecoration: `none`
          }}
        >
        <Image filename="logo.png" className="absolute_top_left" style={{width: 96}}/>
          <span style={{color: "var(--color)", fontFamily: "var(--font-family)", "paddingLeft": 20, "fontSize": 20, "placeSelf": "center"}}>Eartha (EAR)</span>
        </StyledHomeLink>
      </StyledNavTitleWrapper>
       
      <div style={{flex: 1}}>
          <StyledNav ref={node} open={isMenuOpen}>
              <HideSmall >
              {
                  languageList.map(edge => {
                      return <Menu key={edge.id} data={edge} />
                  })
              }
              </HideSmall >
              {
                  headers.map(edge => {
                      return <Menu key={edge.id} data={edge} />
                  })
              }
          </StyledNav>
      </div>
      <HideSmall >
      <MenuToggle ref={button} open={isMenuOpen} onClick={() => updateIsMenuOpen(!isMenuOpen)}> 
      {
          isMenuOpen
                  ? <Image filename="close.svg" className="absolute_top_left" /> 
                  : <Image filename="hamburger.svg" className="absolute_top_left" /> 
      }
      </MenuToggle>
    </HideSmall >
      <ThemeToggler>
          {({ theme, toggleTheme }) => (
              <button className="button" type="button" onClick={() => toggleTheme(theme === "dark" ? 'light' : 'dark')}>
                {theme === "light" ? <Sun color="var(--color)" size={20} /> : <Moon color="var(--color)" size={20} />}
              </button>
          )}
      </ThemeToggler>
    </StyledHeader>
      </StyledHeaderContainer >
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header

