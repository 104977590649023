import React from "react";
import AccordionItem from "./AccordionItem";
import styled from 'styled-components'
import { graphql, useStaticQuery } from "gatsby"
import { useIntl, injectIntl } from "gatsby-plugin-intl"

const Faq = styled.div`
  width: 100%;
  max-width: 960px;
  padding-top: 65px;
  padding-bottom: 80px;
  margin: 0 auto;
  @media(max-width: 960px) {
      width: 90%;
  }
`
const FaqTitle = styled.h1`
    margin: 0;
    padding-bottom: 25px;
`
const FaqList = styled.dl`
    margin: 0;
    padding-bottom: 25px;
`

const Accordion = ({  }) => {
  //   const data = useStaticQuery(graphql`
  //   query {
  //     allQAndAsJson {
  //       edges {
  //         node {
  //           question
  //           answerList
  //         }
  //       }
  //     }
  //   }
  // `)
    const intl = useIntl()
    const qAndAs = intl.messages.qAndAs

  const renderedQuestionsAnswers = qAndAs.map((item, index) => {
    return (
        <div>
          <AccordionItem
            item={item}
          />
        </div>
    );
  });

  return (
    <Faq>
      <FaqTitle className="b_big_font normal_weight" >EAR FAQs</FaqTitle>
      <FaqList >{renderedQuestionsAnswers}</FaqList>
    </Faq>
  );
};

export default Accordion;
