import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default ({ filename, className, style }) => (

  <StaticQuery

    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              publicURL
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {

      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })

      if (!image) return

        if(filename.split('.')[1] === "svg") {
            return <img src={image.node.publicURL} alt={image.node.name} className={className} style={style} />
        }
      
      const imageFluids = image.node.childImageSharp.fluid
      return <Img　fluid={imageFluids} className={className} style={style} />
    }}
  />
)
