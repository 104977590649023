import React from 'react'
import styled from 'styled-components'
import Image from "./Image"
import LinkButton from "./styledComponents/LinkButton"
import Pdf from "./Pdf"
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

const PartnersNav = styled.nav`
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    margin: 0 auto;
    width: 60%;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: space-evenly;
    @media(max-width: 960px) {
        width: 90%;
    }
`
// const PartnersNav = styled.div`
//     grid-template-columns: repeat(4,1fr);
//     display: grid;
// `

const PartnerLink = styled.a`
    font-family: "GT Haptik Regular";
    padding: 0px;
    text-decoration: none;
    margin: 0.25rem 0px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    color: rgb(1, 1, 1);
    background-color: var(--blurCard);
    
    border-radius: 12px;
    transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1) 0s;
`

const PartnerCard = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgba(18,19,29,.0);
    // border: 1px solid hsla(0,0%,100%,.24);
    transition: all .3s;
    margin: 0px 6px 0px 6px;
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    & > div {
        max-height: 120px;
        width: 400px;
        // max-height: 40px;
        /* filter: grayscale(100%); */
    }

`
const Title = styled.h1`
    // margin: 0;
    padding-bottom: 25px;
`

const FindUs = () => {
    const data = useStaticQuery(graphql`
    query {
      allFindusJson {
        edges {
          node {
            text
            Link
            imageUrl
            className
          }
        }
      }
    }
  `)
    let nodes = data.allFindusJson.edges.map(edge => edge.node)

    return (
        <div style={{"marginBottom": "30px"}}>
            <Title className="b_big_font normal_weight" >Find us</Title>
            <PartnersNav>
                {nodes.map((node, index) => (
                    <PartnerLink
                        href={node.Link}
                        rel="noreferrer"
                        target="_blank"
                        key={index}
                        style={{"backgroundColor": "transparent"}}
                    >
                        <PartnerCard >
                            <Image filename={node.imageUrl}  className={"findusImg " + node.className} />
                        </PartnerCard>
                    </PartnerLink>
                ))}

            </PartnersNav>
        </div>
    )

}


export default FindUs;
