import React from 'react';
import "./../styles/communities.scss"
import Image from "./Image"
import { graphql, useStaticQuery } from "gatsby"


const Communities = () => {
    const data = useStaticQuery(graphql`
    query {
      allCommunitiesJson {
        edges {
          node {
            text
            Link
            imageUrl
          }
        }
      }
    }
  `)
    let nodes = data.allCommunitiesJson.edges.map(edge => edge.node)

    return (
        <nav className="communitiesNav">
            {nodes.map((node, index) => (
                <a 
                    className="communityLink"
                    href={node.Link}
                    rel="noreferrer"
                    target="_blank" key={index}
                    style={{"backgroundColor": "transparent"}}
                >
                    <div className="communityCard"  >
                        <Image filename={node.imageUrl} />
                    </div>
                </a>
            ))}
        </nav>
    )
}
export default Communities

