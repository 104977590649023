import React from 'react'
import styled from 'styled-components'
import Image from "./Image"
import LinkButton from "./styledComponents/LinkButton"
import Pdf from "./Pdf"
import { useIntl, injectIntl } from "gatsby-plugin-intl"


const Wrap = styled.div`
      width: 80%;
    padding: 10px;
    @media (min-width: 960px) {
        width: 960px; 
    padding: 0px;
    }
    margin: 0 auto 50px;
`

const Container = styled.div`
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #e6007a;
    padding: 14px;
`

const TextContaienr = styled.div`
`

const TokenIs = styled.h3`
    text-align: center;
    font-family: "Montserrat", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #e6007a;
`

const Text = styled.p`
    text-align: center;
`

const SmallText = styled.p`
    text-align: center;
    font-size: small;
    padding: 0 10px;
`
const SmallText2 = styled.p`
    text-align: center;
    font-size: small;
    padding: 0 10px;
`

const Link = styled.a`
    display: inline-flex;
    place-items: center;
    color: #e6007a;
    box-shadow: none;
    cursor: pointer;
    text-decoration: none;
`

const BuyButtonContainer = styled.div`
    display: flex;
    place-content: center;
    justify-content: center;
    width: 100%;
`

const SmallTextUl = styled.ul`
    padding: 0px 100px;
    margin: 0px 10px;
    list-style: none;
    @media (max-width: 960px) {
        padding: 0px 10px;
    }
`

const IcoInform = props => {
    const intl = useIntl()
    const Ico = intl.messages.Ico

    return (
        <Wrap>
            <Container> 
                <TextContaienr>
                    <TokenIs>
                        <strong> {Ico.PRESALE} <br /> {Ico.DATE} </strong>
                    </TokenIs>
                    <BuyButtonContainer>
                        <LinkButton  href="/" className="" target={"_self"} text={Ico.Buy} filled={true} toolTip="" themeColor="red" disabled={true} />
                    </BuyButtonContainer>

                    <Text>{Ico.OnlyEthe}</Text>

                    <Pdf 
                        filename="/static/HowToBuyEAR.pdf" 
                        isStatic={true} 
                        isText={true} 
                        text={Ico.HowToBuy} 
                        style={{"textAlign": "center", "width": "100%", "display": "block"}}

                    />

                    <SmallText className="mb-0">
                        {Ico.Risk}
                    </SmallText>
                    <SmallText className="mt-0" style={{"textAlign": "left", "margin": "0px 10px"}}>
                        <SmallTextUl >
                            {Ico.RiskList.map(l => (
                            <li>{l}</li>
                            ))}
                        </SmallTextUl>
                    </SmallText>
                </TextContaienr>
            </Container>
        </Wrap>
    )
}
export default IcoInform
