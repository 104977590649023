import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { useIntl, injectIntl } from "gatsby-plugin-intl"

const HideSmall = styled.span`
  @media (max-width: 960px) {
    display: none;
  }
`
const ShowSmall = styled.span`
  @media (min-width: 960px) {
    display: none;
  }
`

const HowToWorkArea = styled.div`
        p {
            font-size: 1em;
            line-height: 1.75em;
            margin: 0;
            padding: 20px;
            counter-increment: section;
            position: relative;
        }
        p:first-child {
            border-top: 0;
            border-top-right-radius:0;
            border-top-left-radius:0;
        }
        p:last-child {
            border-bottom-right-radius:0;
            border-bottom-left-radius:0;
        }
        @media (max-width: 960px) {
          p{
            margin: 0 auto;
            width: 68%;
          }
        }
        @media(min-width: 960px) {
        margin: .5rem auto 1.45rem;
        max-width: 1020px;
        }
`

const EarthaToken = () => {
    const intl = useIntl()
    const earthaToken = intl.messages.earthaToken

    return (
        <HowToWorkArea>
        <h1 style={{"marginTop": "4rem"}} className="b_big_font normal_weight" > {earthaToken.title} </h1>
            <HideSmall>
                <div>
                    {earthaToken.textList.map((x, i) => (
                        <p key={i}>
                                <h3  className={x.className} style={{"marginBottom": 0, "fontWeight": "400", "fontFamily": "var(--font-family)", "textAlign": "left"}} >{x.text} <br/></h3>
                        </p>
                    ))}
                </div>
            </HideSmall>
            <ShowSmall>
                <div>
                    {earthaToken.textList.map((x, i) => (
                        <p key={i}>
                            <span style={{"marginBottom": 0, "fontWeight": "400", "fontFamily": "var(--font-family)"}} className={x.className}>{x.text} <br/> </span>
                        <br />
                        </p>
                    ))}
                </div>
            </ShowSmall>
        </HowToWorkArea>
    )
}

export default EarthaToken
