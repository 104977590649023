import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LinkButton from "./styledComponents/LinkButton"
import LinkText from "./styledComponents/LinkText"

export default ({ filename , className, text, toolTip, filled, isStatic=false, isText=false, ...others}) => (

  <StaticQuery
    query={graphql`
      query {
        pdfs: allFile(filter: { extension: { eq: "pdf" } }) {
            edges {
              node {
                publicURL
                name
              }
            }
          }
        }
      `}

    render={(data) => {
        if(isStatic) {
            if(isText) {
                return <LinkText className={className} href={filename} text={text} {...others} />
            } else {
                return <LinkButton className={className} filled={filled} href={filename} text={text}  toolTip={toolTip} download={true}> </LinkButton>
            }
        }

      const pdf = data.pdfs.edges.find(n => {
        return n.node.name === filename.split('.')[0];
      })

      if (!pdf) return
        if(isText) {
                return <LinkText className={className} href={filename} text={text} {...others} />
        } else {
            return <LinkButton className={className} filled={filled} href={pdf.node.publicURL} text={text}  toolTip={toolTip} download={true}> </LinkButton>
        }
    }}
  />
)

