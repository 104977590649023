import Collapsible from 'react-collapsible';
import React from "react";
const AccordionItem = ({
  item,
}) => {
    return (
        <Collapsible
            trigger={item.question} 
            className="questionPanel"
            triggerClassName="questionButton"
            triggerOpenedClassName="questionButton"
        >
            <p>
            {item.answerList.map(answer => (
                <span>
                    {
                        answer.split('<br>').length > 1
                            ? answer.split('<br>').map(x => (
                                <React.Fragment >
                                    <span dangerouslySetInnerHTML={{__html: x}} />
                                    <br /><br />
                                </React.Fragment>
                            ))
                            :<span dangerouslySetInnerHTML={{__html: answer}} />
                    } <br /> <br />
                    </span>
            ))}
            </p>
        </Collapsible>
    );
}

export default AccordionItem;
