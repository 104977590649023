import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';


const PdfText = styled.a`
    color: #e6007a;
`
export default class LinkText extends React.Component {
    render() {
        const uuid = uuidv4();
        const props = this.props
        return (
            <PdfText target="_blank"  {...props} >
                {props.text}
            </PdfText >
        )
    }
}
