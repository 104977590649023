import React from 'react'
import { Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import "./../styles/chart.scss"


const Chart = ({type, data, options, className}) => {

    if(type === "doughnut") {
        return (
            <div className={className}>
                <Doughnut data={data} options={options} />
            </div>
        )
    }

    return (
        <div className={className}>
            <Pie data={data} options={options} />
        </div>
    )
}

export default Chart

