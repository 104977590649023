import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
    // position: absolute;
    // bottom: 0px;
    // width: 100%;
    text-align: center;
`
const StyledP = styled.p`
    margin: 0px;
`

const Footer = props => {

    return (
        <StyledFooter >
            <StyledP>&copy; Eartha </StyledP>
        </StyledFooter>
    )
}
export default Footer
