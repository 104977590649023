import React from 'react'
import Header from '../components/header'
import ReactTooltip from "react-tooltip";
import Footer from '../components/footer'
import "./../styles/layout.scss";
import { Helmet } from "react-helmet"


class Layout extends React.Component {
    render() {
        const { children } = this.props
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Eartha</title>
                    <description>Eartha Homepage</description>
                    <link rel="canonical" href="https://eartha.org" />
                </Helmet>
                <Header />
                <div className="fixed_background main_background" /> 
                <div className="layout">
                    { children }
                </div>
            <Footer />
                <ReactTooltip place="top" effect="solid" />
            </React.Fragment>
        )
    }
}

export default Layout

